






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import makeSelectOptions from "@common/util/makeSelectOptions";
import productGroupTypes from "@/constants/productGroupTypes";
import purchaseCommandTransportLines from "@/constants/purchaseCommandTransportLines";
import purchaseShippingCompanyStatuses from "@/constants/purchaseShippingCompanyStatuses";

export default Vue.extend({
  data() {
    return {
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
        },
        content: {
          name: "Quản lý đơn vị vận chuyển mua hàng",
          urlEnabled: true,
          search: {},
          displayFields: {
            name: {
              text: "Tên",
              sortable: true,
            },
            status: {
              text: "Trạng thái",
              sortable: true,
              options: {
                label(value) {
                  return purchaseShippingCompanyStatuses[value || ""];
                },
                attrs(value) {
                  const attrs: any = {};
                  if (value === "nhd") {
                    attrs.color = "red";
                  }
                  return attrs;
                },
              },
            },
            createdTime: {
              text: "Ngày tạo",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            action: {},
          },
          topActionButtons: {
            insert: {
              target: {
                dialog: {
                  attrs: {
                    width: "80%",
                  },
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: {
                target: {
                  dialog: {
                    attrs: {
                      width: "80%",
                    },
                    ext: {
                      subTitleMaker: "name",
                    },
                  },
                },
              },
              delete: {},
            },
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("purchaseShippingCompanies", "findAll", options);
              return [items, count];
            },
            async insert(item) {
              return await coreApiClient.call("purchaseShippingCompanies", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "purchaseShippingCompanies",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("purchaseShippingCompanies", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              name: {
                attrs: {
                  label: "Tên",
                  required: true,
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả",
                },
              },
              status: {
                type: "select",
                attrs: {
                  label: "Trạng thái",
                  required: true,
                  items: makeSelectOptions(purchaseShippingCompanyStatuses),
                },
                ext: {
                  defaultValue: "hd",
                },
              },
              tariffItems: {
                type: "XArrayInput",
                attrs: {
                  label: "Bảng phí",
                  xOptions: {
                    content: {
                      itemProperties: {
                        description: {
                          attrs: {
                            label: "Mô tả",
                          },
                        },
                        transportLine: {
                          type: "select",
                          attrs: {
                            label: "Line vận chuyển",
                            required: true,
                            items: makeSelectOptions(purchaseCommandTransportLines),
                          },
                          ext: {
                            defaultValue: "sea",
                          },
                        },
                        productGroupType: {
                          type: "select",
                          attrs: {
                            label: "Loại hàng",
                            required: true,
                            items: makeSelectOptions(productGroupTypes),
                          },
                          ext: {
                            defaultValue: "normal",
                          },
                        },
                        localPrice: {
                          type: "number",
                          required: true,
                          attrs: {
                            label: "Đơn giá vận chuyển nguyên tệ",
                          },
                        },
                      },
                      template: {
                        formOptions: {
                          content: {
                            colLength: 3,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            insertForm: {
              content: {
                fieldNames: ["name", "description", "status", "tariffItems"],
              },
            },
            editForm: {
              content: {
                fieldNames: ["name", "description", "status", "tariffItems"],
              },
            },
            delete: {
              confirmDisplayField: "name",
            },
          },
        },
      },
    };
  },
});
